<template>
  <div class="my-4">
    <div class="date-container loading">
      <span class="date-text"> </span>
    </div>
    <div class="ml-4">
      <!-- Event Title -->
      <div class="event-title loading"></div>

      <!-- Event Starting TIme -->
      <div class="loading" style="min-width: 100%; min-height: 26px"></div>

      <!-- Event Location -->
      <div class="loading" style="min-width: 100%; min-height: 26px"></div>

      <!-- Event Education Topic -->
      <div class="loading" style="min-width: 100%; min-height: 26px"></div>

      <!-- Event Zoom Link -->
      <div class="loading" style="min-width: 100%; min-height: 26px"></div>

      <!-- Event Calendar Button -->
      <div class="mt-2">
        <v-btn
          disabled
          variant="outlined"
          size="small"
          class="loading"
          style="min-width: 170px; max-height: 28px"
        >
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.date-container {
  border-bottom: 1px solid rgb(17, 75, 95);
  font-size: 1.2em;
}
.date-text {
  padding: 4px 8px;
  display: inline-block;
  min-width: 50%;
  min-height: 37px;
}
.event-title {
  font-size: 1.5em;
  text-transform: capitalize;
  font-variant: small-caps;
  min-width: 100%;
  min-height: 36px;
}
</style>

<script>
export default {
  name: "EventSkeleton",
  data: () => ({}),
};
</script>
