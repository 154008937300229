<template>
  <template v-for="(blog, index) in blogs" :key="index">
    <BlogPreview v-if="index < 3" :blog="blog" />
  </template>
  <v-row>
    <v-col align="center" class="mt-4">
      <v-btn color="primary-lighten-5" href="/blog/1">
        <v-icon>mdi-calendar-plus</v-icon>
        <span class="ml-2">Read All Articles</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, ref, onErrorCaptured } from "vue";
import BlogPreview from "@/components/details/BlogPreview.vue";
import api from "@/api";
import blogData from "@/test-data/blogs.json";
const loadBlogData = async () => {
  if (process.env.NODE_ENV === "production") {
    return (await api.getPostByPage(1)).data;
  } else {
    return blogData.data;
  }
};

export default defineComponent({
  name: "HomeBlogLayout",
  async setup() {
    const error = ref();

    onErrorCaptured((e) => {
      error.value = e;
    });

    const blogs = ref(await loadBlogData());

    return { blogs, error };
  },
  components: {
    BlogPreview,
  },
});
</script>
