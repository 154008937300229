import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
  },
  {
    path: "/membership",
    name: "membership",
    component: () =>
      import(/* webpackChunkName: "membership" */ "@/views/MembershipView.vue"),
  },
  {
    path: "/bylaws",
    name: "bylaws",
    component: () =>
      import(/* webpackChunkName: "bylaws" */ "@/views/BylawsView.vue"),
  },
  {
    path: "/styles",
    name: "styles",
    component: () =>
      import(/* webpackChunkName: "styles" */ "@/views/BeerStylesView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/ContactView.vue"),
  },
  {
    path: "/boty",
    name: "boty",
    component: () =>
      import(/* webpackChunkName: "boty" */ "@/views/BoTYView.vue"),
  },

  {
    path: "/events",
    name: "events",
    component: () =>
      import(/* webpackChunkName: "comp" */ "@/views/EventView.vue"),
  },
  {
    path: "/blog/:id?",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "@/views/BlogView.vue"),
  },
  {
    path: "/unsubscribe/:id?",
    name: "unsubscribe",
    component: () =>
      import(
        /* webpackChunkName: "subscription" */ "@/views/SubscriptionView.vue"
      ),
  },
  {
    path: "/subscribe/:id?",
    name: "subscribe",
    component: () =>
      import(
        /* webpackChunkName: "subscription" */ "@/views/SubscriptionView.vue"
      ),
  },
  {
    path: "/2023confirmmembership",
    name: "ConfirmMembership",
    component: () =>
      import(
        /* webpackChunkName: "ConfirmMembership" */ "@/views/ConfirmMembershipView.vue"
      ),
  },
  {
    path: "/members",
    name: "Member Links",
    component: () =>
      import(/* webpackChunkName: "MemberLinks" */ "@/views/MemberLinks.vue"),
  },
  {
    path: "/:PathMatch(.*)*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/PageNotFound.vue"),
  },
];

const router = createRouter({
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
