<template>
  <v-col cols="12" md="4">
    <v-card>
      <v-card-title style="min-height: 48px" class="loading"></v-card-title>
      <v-card-subtitle style="min-height: 20px; max-width: 40%" class="loading">
      </v-card-subtitle>
      <v-card-text>
        <div class="blog-body-preview" />
      </v-card-text>
      <v-card-actions>
        <v-btn disabled text class="loading" style="min-width: 110px"></v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
  <v-col cols="12" md="4">
    <v-card>
      <v-card-title style="min-height: 48px" class="loading"></v-card-title>
      <v-card-subtitle style="min-height: 20px; max-width: 40%" class="loading">
      </v-card-subtitle>
      <v-card-text>
        <div class="blog-body-preview" />
      </v-card-text>
      <v-card-actions>
        <v-btn disabled text class="loading" style="min-width: 110px"></v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
  <v-col cols="12" md="4">
    <v-card>
      <v-card-title style="min-height: 48px" class="loading"></v-card-title>
      <v-card-subtitle style="min-height: 20px; max-width: 40%" class="loading">
      </v-card-subtitle>
      <v-card-text>
        <div class="blog-body-preview loading" />
      </v-card-text>
      <v-card-actions>
        <v-btn disabled text class="loading" style="min-width: 110px"></v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
  <v-row>
    <v-col align="center" class="mt-4">
      <v-btn disabled class="loading" style="min-width: 210px"> </v-btn>
    </v-col>
  </v-row>
</template>

<style scoped>
.blog-body-preview {
  min-height: 100px;
  max-height: 100px;
  height: 100%;
  overflow: clip;
  animation: pulse-bg 1s infinite;
}
</style>

<script>
export default {
  name: "BlogPreviewSkeleton",
  setup() {},
  data: () => ({}),
};
</script>
