<template>
  <v-row class="img-members">
    <v-col cols="12" class="py-4">
      <v-img src="@/assets/ieb-logo.svg" class="img-logo"></v-img>
    </v-col>
  </v-row>

  <v-container class="my-5">
    <v-row>
      <v-col cols="12" md="8">
        <div class="mr-8">
          <h1>Welcome to the Inland Empire Brewers Homebrew Club</h1>
          <p>
            The Inland Empire Brewers Homebrew Club is a 501(c)(3) non-profit
            organization that is dedicated to promoting and educating both
            members and the public about homebrewed beverages. We strive to
            advance the art and science of making better beer and gaining a
            greater appreciation of homebrewed beer.
          </p>
          <p>
            The club typically meets on the second Saturday starting at 1:00 pm
            or Friday starting at 7:00 pm of each month. Meetings are held at
            various breweries or members' homes. Check with us on Facebook or
            view the
            <router-link to="/events">events page</router-link> for the current
            month's date, time and location.
          </p>
          <p>
            Along with promoting and educating both members and the public about
            homebrewed beverages, we strive to help our local community by
            participating in charity events such as Amber Waves of Grains, Lake
            Arrowhead Brewfest and several other charitable events.
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <h2>Next Upcoming Event</h2>
        <Suspense>
          <HomeEvent />
          <template #fallback>
            <EventSkeleton />
          </template>
        </Suspense>
      </v-col>
    </v-row>
  </v-container>

  <v-row class="img-taps py-6">
    <v-container>
      <v-row>
        <v-col cols>
          <h2 class="mb-4" style="color: white">
            Check out what is going on with the Inland Empire Brewers
          </h2>
          <v-row>
            <Suspense>
              <HomeBlog />
              <template #fallback>
                <BlogPreviewSkeleton />
              </template>
            </Suspense>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-row>

  <v-container>
    <v-row justify="center" class="text-center">
      <v-col cols="12" md="4" lg="3">
        <Subscription />
      </v-col>

      <v-col cols="12" md="4" lg="3"> </v-col>

      <v-col cols="12" md="4" lg="3">
        <h3>Get in Touch</h3>
        <p class="color-white">
          Interested in homebrew or interested in joining us for a meeting?
        </p>
        <p>
          <router-link to="/contact"> Feel free to contact us! </router-link>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import HomeBlog from "@/components/layout/HomeBlog.vue";
import BlogPreviewSkeleton from "@/components/skeletons/BlogPreviewSkeleton.vue";
import HomeEvent from "@/components/layout/HomeEvent.vue";
import EventSkeleton from "@/components/skeletons/EventSkeleton.vue";
import Subscription from "@/components/details/Subscription.vue";

export default defineComponent({
  name: "HomeView",
  setup() {
    useMeta({
      title: "Home",
      description:
        "The Inland Empire Brewers Homebrew Club is a 501(c)(3) non-profit organization that is dedicated to promoting and educating both members and the public about homebrewed beverages. We strive to advance the art and science of making better beer and gaining a greater appreciation of homebrewed beer.",
      keywords:
        "ie brewers, inland empire brewers,homebrew, ie homebrew, southern california regional championship, riverside, corona, inland empire",
    });
  },
  components: {
    HomeBlog,
    BlogPreviewSkeleton,
    Subscription,
    HomeEvent,
    EventSkeleton,
  },
});
</script>
