<template>
  <v-col cols="12" md="4">
    <v-card>
      <v-card-title>{{ blog.title }}</v-card-title>
      <v-card-subtitle>
        {{ new Date(blog.createdAt).toLocaleString("en-us") }}
      </v-card-subtitle>
      <v-card-text>
        <div v-html="blog.content" class="blog-body-preview" />
      </v-card-text>
      <v-card-actions>
        <v-btn :to="'/blog/1#' + blog.title.replace(/\s+/g, '')">
          Read More
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<style scoped>
.blog-body-preview {
  min-height: 100px;
  max-height: 100px;
  height: 100%;
  overflow: clip;
  -webkit-overflow-scrolling: clip;
}
</style>
<script>
export default {
  name: "BlogPreview",
  props: ["blog"],
};
</script>
