import axios from "axios";

const client = axios.create({
  baseURL: "https://api.iebrewers.org",
  json: true,
});

export default {
  async execute(method, resource, data) {
    return client({
      method,
      url: resource,
      data,
      headers: {},
    }).then((req) => {
      return req.data;
    });
  },
  getPostByPage(page) {
    return this.execute("get", "/v1/blog/" + page);
  },
  getPostByID(id) {
    return this.execute("get", "/v1/blog/" + id);
  },
  getEvents() {
    return this.execute("get", "/v1/calendar");
  },
  createSubscription(data) {
    return this.execute("post", "/v1/subscribe", data);
  },
  confirmSubscription(data) {
    return this.execute("get", "/v1/subscribe/" + data);
  },
  removeSubscription(data) {
    return this.execute("delete", "/v1/subscribe/" + data);
  },
  createContact(data) {
    return this.execute("post", "/v1/contact/", data);
  },
  createMembership(data) {
    return this.execute("post", "/v1/member/", data);
  },
};
