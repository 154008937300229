<template>
  <v-row class="mt-3" style="background-color: rgb(17, 75, 95); color: white">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <a
            href="https://facebook.com/inlandempirebrewers"
            target="facebook"
            class="mr-4"
          >
            <img :src="facebook" width="45" height="45" alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/inlandempirebrewers/"
            target="instagram"
          >
            <img :src="instagram" width="45" height="45" alt="Instagram" />
          </a>
        </v-col>
        <v-col cols="12">
          <div class="text-center">
            Copyright &copy; Inland Empire Brewers

            {{ new Date().getFullYear() }}. All rights reserved.
          </div>
        </v-col>
        <v-col cols="12">
          <div class="text-center">
            <a
              href="www.interserver.net"
              target="Powered by InterServer"
              style="color: white"
            >
              Powered by InterServer
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
export default {
  name: "FooterBar",
  setup() {},
  data() {
    return {
      instagram: require("@/assets/instagram-64.png"),
      facebook: require("@/assets/fb-logo-64.png"),
    };
  },
  components: {},
};
</script>
